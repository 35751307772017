import { Component, OnInit, Input } from '@angular/core';

import { Noticia } from '../../model/Noticia';

@Component({
  selector: 'app-noticia-inline',
  templateUrl: './noticia-inline.component.html',
  styleUrls: ['./noticia-inline.component.css']
})
export class NoticiaInlineComponent implements OnInit {

  @Input() noticia: Noticia;

  constructor() { }

  ngOnInit() {
  }

}
