import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { MuralNoticiasComponent } from './mural-noticias/mural-noticias.component';
import { NoticiaInlineComponent } from './noticia-inline/noticia-inline.component';
import { ThumbFotoComTituloComponent } from './thumb-foto-com-titulo/thumb-foto-com-titulo.component';
import { NoticiaDetalheComponent } from './noticia-detalhe/noticia-detalhe.component';
import { FiltroPeloTitulo } from './mural-noticias/filtro-pelo-titulo.pipe';
import { DarkenOnHOverModule } from '../shared/directives/darken-on-hover/darken-on-hover.module';
import { FotoComUrlServidor } from './noticia-detalhe/foto-com-url-servidor.pipe';
import { CarouselFotosComponent } from './carousel-fotos/carousel-fotos.component';

@NgModule({
  declarations: [
    MuralNoticiasComponent,
    NoticiaInlineComponent,
    ThumbFotoComTituloComponent,
    NoticiaDetalheComponent,
    FiltroPeloTitulo,
    FotoComUrlServidor,
    CarouselFotosComponent

  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,

    DarkenOnHOverModule
  ],
  exports: [ // Estou acessando de fora apenas o componente mural de noticias
    MuralNoticiasComponent,
    // NoticiaInlineComponent,
    // ThumbFotoComTituloComponent
  ]
})
export class NoticiasModule { }
