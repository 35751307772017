import { Component, OnInit, Input } from '@angular/core';

import { Noticia } from './../../model/Noticia';

@Component({
  selector: 'app-thumb-foto-com-titulo',
  templateUrl: './thumb-foto-com-titulo.component.html',
  styleUrls: ['./thumb-foto-com-titulo.component.css']
})
export class ThumbFotoComTituloComponent implements OnInit {

  @Input() noticia: Noticia;

  constructor() { }

  ngOnInit() {
  }

}
