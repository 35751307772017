import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NoticiaDetalheComponent } from './noticias/noticia-detalhe/noticia-detalhe.component';
import { MuralNoticiasComponent } from './noticias/mural-noticias/mural-noticias.component';

const routes: Routes = [
    { path: 'noticia/:noticiaId',     component: NoticiaDetalheComponent},
    { path: 'noticias',               component: MuralNoticiasComponent},
    { path: '**',                     component: MuralNoticiasComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
