import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Noticia } from '../model/Noticia';

const API = 'http://www.pmsaraguaia.pa.gov.br/transparencia'
// 'http://184.107.94.164:19759/portal_cmpd'
// 'http://192.175.112.170:22621/portal-pmsambar'; - PMSAMBAR
// const API = 'http://localhost:8080';

@Injectable({providedIn: 'root'})
export class NoticiasService {

    constructor(private http: HttpClient) {}

    listarTodasNoticias() {
      return this.http
        .get<Noticia[]>(API + '/noticias/buscar/99');
    }

    findById(id: string) {
      return this.http
        .get<Noticia>(API + '/noticias/findByCodigo/' + id);
    }
}
