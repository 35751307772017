import { Pipe, PipeTransform } from '@angular/core';

import { Noticia } from './../../model/Noticia';

@Pipe({ name: 'fotoComUrlServidor' })
export class FotoComUrlServidor implements PipeTransform {

  API_FOTO = 'http://www.pmsaraguaia.pa.gov.br/transparencia/fotos/';

  transform(imagem: string) {
    return this.API_FOTO + imagem;
  }

}
