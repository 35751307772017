import { Component, OnInit, Input } from '@angular/core';

import { NoticiaFoto } from './../../model/NoticiaFoto';

@Component({
  selector: 'app-carousel-fotos',
  templateUrl: './carousel-fotos.component.html',
  styleUrls: ['./carousel-fotos.component.css']
})
export class CarouselFotosComponent implements OnInit {

  @Input() galeriaDeFotos: NoticiaFoto[];

  constructor() { }

  ngOnInit() {

  }

}
