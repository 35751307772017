import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';


import { Noticia } from '../../model/Noticia';
import { NoticiasService } from '../noticias.service';

@Component({
  selector: 'app-noticia-detalhe',
  templateUrl: './noticia-detalhe.component.html',
  styleUrls: ['./noticia-detalhe.component.css']
})
export class NoticiaDetalheComponent implements OnInit {

  noticia$: Observable<Noticia>;

  constructor(
    private route: ActivatedRoute,
    private noticiaService: NoticiasService
  ) { }

  ngOnInit() {
    const noticiaId = this.route.snapshot.params.noticiaId;
    this.noticia$ = this.noticiaService.findById(noticiaId);
  }

}
