import { Pipe, PipeTransform } from '@angular/core';

import { Noticia } from './../../model/Noticia';

@Pipe({ name: 'filtroPeloTitulo' })
export class FiltroPeloTitulo implements PipeTransform {

  transform(noticias: Noticia[], filtro: string) {
    filtro = filtro.trim().toLowerCase();

    if(filtro) {
      return noticias.filter( noticia =>
        noticia.titulo.toLowerCase().includes(filtro)
      );
    } else {
      return noticias;
    }
  }

}
